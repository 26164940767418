<template>
  <div style="color: rgb(55, 53, 47)">
    <MobileHeader v-if="isMobile"/>
    <BeeHeader v-else/>
    <LayoutArticle :class="isMobile ? 'mobile-view' : 'py-10'">
      <div class="max-w-prose mx-auto" :class="isMobile ? 'text-xs p-3' : 'text-lg'">
        <article id="8323921b-8890-4134-81f2-9293777a66d5" class="page sans">
          <header>
            <h1 class="page-title text-center">HÌNH THỨC THANH TOÁN</h1>
          </header>
          <div class="page-body">
            <p id="2cdac7ed-2ea8-4dde-9356-db61262a6339" class="">Metric Ecom Việt có bộ phận tư vấn, chăm sóc khách hàng luôn sẵn sàng tiếp nhận yêu cầu và thông tin từ bạn. Vì vậy ngay khi chúng tôi nhận được liên hệ, yêu cầu tư vấn của bạn, chúng tôi sẽ liên lạc lại ngay với bạn bằng điện thoại hoặc email để tư vấn thêm. Khi bạn có nhu cầu mua phần mềm phân tích thị trường và quản lý bán hàng của chúng tôi trên trang web: <strong>metric.vn</strong>, bạn có thể thực hiện việc tìm kiếm gói dịch vụ&nbsp; của chúng tôi và tiến hành thanh toán theo quy trình sau:</p>
            <ul id="d5e7e580-eff9-49cf-ae5c-746e4629ec3e" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Bước 1:</strong> Truy cập website: <strong>metric.vn</strong> bạn chọn mục <strong>“Đăng nhập”</strong> và đăng nhập vào tài khoản gmail của mình để thuận lợi cho việc mua + chọn gói dịch vụ</li>
            </ul>
            <figure id="2973f07f-b55f-4f68-8ee3-a765e38dba13" class="image">
              <img src="https://lh3.googleusercontent.com/pw/AM-JKLXHjoaoio9az2veTqd6OKE2fWp3wCm27jNyDGD7p-umuVNdRB2VIRwCD6kLtA-yNu0OKwQ8Gf_MYDxhjK6M8t0HkvzI0Z5PgP8iriUQhynKheB6n0Iz5f_QwFkgFfSYiy79pvmWrmk6w-3MMS9LwdY=w1901-h434-no">
            </figure>
            <figure id="e58bc9fc-8508-4c15-996a-0037e7a08a62" class="image">
              <img src="https://lh3.googleusercontent.com/pw/AM-JKLVYC8XYQThbNFzp5dW3oP_sT9IIammoR0JEu0psxTTIGgno_tsA7mpsW3UMYkTtMHz9oWZqBEe2p36AVfoGqdF17jssRvPayHpS06PNif9N-J7xodX6GacidxJtbgJZXpf0I4FBTfbNKbBoL6AzKd4=w1913-h615-no">
            </figure>
            <ul id="141370f3-2d0f-4bec-8b5e-264df17df941" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Bước 2:</strong> Sau khi đã đăng nhập tài khoản gmail, bạn vào mục <strong>“Nâng cấp tài khoản”</strong> để chọn gói dịch vụ phù hợp với nhu cầu của mình</li>
            </ul>
            <figure id="216f3b86-9de2-4f4e-bd44-9928e65b7ff6" class="image">
              <img src="https://lh3.googleusercontent.com/pw/AM-JKLXDnUW5dia3uWaijH-xSjMDdYuvHKbWqY2BCgfWzXo26IH2fHjFYfrETLf9WjLao6eNUhyl14erlrJv2eQkqNXrAPqiGxx6OKg8QesedKavsOv5vVnbZ7vpfZguuHoMBii85vcpQdzOc3ON1XIlln0=w1901-h437-no">
            </figure>
            <figure id="f39d1934-b40e-4327-9d00-0c55522bca56" class="image">
              <img src="https://lh3.googleusercontent.com/pw/AM-JKLVDo5QeOe0wKj2a1GXwJToCuginsK5wOXSAvo7hQUF_BbukNpO_Co8dJt-oh4v6-VNMD6YgeIuDaQUuqyuZlidXIpoJhOymI-8XXIp548hdiN2Gp8hsPLNsJSK9BJ4tkMasl4NVEKZxT0_AFsrCuEI=w1895-h891-no">
            </figure>
            <ul id="e4c40767-5f84-4459-9693-7ed9138db1ad" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Bước 3:</strong> Sau khi chọn được gói dịch vụ phù hợp. Bạn tiến hành thanh toán (chuyển khoản) theo thông tin số tài khoản được chúng tôi công khai trên gói cước bạn chọn. <strong>Lưu ý:</strong> Bạn nên đọc kỹ thông tin về: <em>Số tài khoản; chủ tài khoản; tên ngân hàng; nội dung chuyển khoản</em> để tránh sai sót trong quá trình chuyển khoản và để hệ thống của chúng tôi xác nhận khoản thanh toán của bạn được nhanh chóng.</li>
            </ul>
            <figure id="88b30f13-5b13-4c40-bc6a-469dfa9517da" class="image">
              <img src="https://lh3.googleusercontent.com/pw/AM-JKLXmXtPTYEVk7g452jTjSzYK0fdljiw4N4aVfOrpTIbeVzQntQg1126yJzxMzbBMtVwNBG1FmGiVIbHWfXBl7foTl72gEILcmSx4SKpDRJlmueNFrmxTlLAtgmHvvEggtZ9Io84rBDTYbldM8mNs_1A=w1896-h732-no">
            </figure>
            <ul id="5e16cc44-d21c-400f-ba08-d6b7a1673628" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Bước 4:</strong> Sau khi chuyển khoản thành công, bạn đợi khoảng 2 – 5 phút, sau đó bạn click vào mục <strong>“Xác nhận đã chuyển khoản”</strong>. Hệ thống sẽ xác nhận chuyển khoản thành công và bạn có thể bắt đầu sử dụng phần mềm phân tích thị trường này của chúng tôi (<strong>Lưu ý:</strong> Nếu bạn chưa biết cách sử dụng phần mềm phân tích thị trường này của chúng tôi như thế nào, bạn có thể truy cập: http://hotro.metric.vn/ để xem Hướng dẫn sử dụng).</li>
            </ul>
            <figure id="00d65762-e9c5-4f79-b974-4d64b5f496ed" class="image">
              <img src="https://lh3.googleusercontent.com/pw/AM-JKLUOEdDzpEBXE1xkorwkb0e4jXIUKy4yvQWkxk0SepANFwB5OkiD6Y4MC5cdznQoVXbuK44UrTyhGbENzen_0vVyQmkd6Y9edYawro7saGd737r1_G35Nrl4bSFDj2vZExG5aeHq3tGiODHxYCH6hcs=w1896-h732-no">
            </figure>
            <p id="83f38e4e-c8c8-4136-92ae-7daed7626776" class=""><em><strong>Mọi vướng mắc, bạn vui lòng liên hệ với chúng tôi để được giải đáp:</strong></em></p>
            <p id="604ea0fa-9865-487a-8e9d-01288391b95d" class="">CÔNG TY CỔ PHẦN KHOA HỌC DỮ LIỆU</p>
            <p id="cf2ae18a-4f4d-480a-a800-18dd68ef14ad" class="">+ Trụ sở chính: Tầng 6 Tòa nhà AZ Lâm Viên, 107A Nguyễn Phong Sắc, phường Dịch Vọng Hậu, quận Cầu Giấy, thành phố Hà Nội, Việt Nam</p>
            <p id="059762cf-90a2-46d9-a03b-1aad13722371" class="">+ Điện thoại: 033.806.2221</p>
            <p id="6fce14b2-6231-4500-b80b-579025390850" class="">+ Email: metric@ecomviet.vn</p>
          </div>
        </article>
      </div>
    </LayoutArticle>
    <BeeFooter/>
  </div>
</template>

<script>
import BeeHeader from "@/components/partial/header/BeeHeader";
import BeeFooter from "@/components/partial/footer/BeeFooter";
import LayoutArticle from "@/layout/LayoutArticle";
import MobileHeader from "@/components/partial/header/MobileHeader";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "PaymentMethodView",
  components: {
    BeeHeader,
    BeeFooter,
    LayoutArticle,
    MobileHeader
  },
  mounted() {
    this.$router.push({ name: "Home" });
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style scoped>

/* cspell:disable-file */
/* webkit printing magic: print all background colors */
html {
  -webkit-print-color-adjust: exact;
}
* {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
}

html,
body {
  margin: 0;
  padding: 0;
}
@media only screen {
  body {
    margin: 2em auto;
    max-width: 900px;
    color: rgb(55, 53, 47);
  }
}

body {
  line-height: 1.5;
  white-space: pre-wrap;
}

a,
a.visited {
  color: inherit;
  text-decoration: underline;
}

.pdf-relative-link-path {
  font-size: 80%;
  color: #444;
}

h1,
h2,
h3 {
  letter-spacing: -0.01em;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75em;
}

.mobile-view .page-title {
  font-size: 1rem;
  font-weight: 600;
}
h1 {
  font-size: 1.875rem;
  margin-top: 1.875rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.25rem;
}

.source {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1.5em;
  word-break: break-all;
}

.callout {
  border-radius: 3px;
  padding: 1rem;
}

figure {
  margin: 1.25em 0;
  page-break-inside: avoid;
}

figcaption {
  opacity: 0.5;
  font-size: 85%;
  margin-top: 0.5em;
}

mark {
  background-color: transparent;
}

.indented {
  padding-left: 1.5em;
}

hr {
  background: transparent;
  display: block;
  width: 100%;
  height: 1px;
  visibility: visible;
  border: none;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
  max-width: 100%;
}

@media only print {
  img {
    max-height: 100vh;
    object-fit: contain;
  }
}

@page {
  margin: 1in;
}

.collection-content {
  font-size: 0.875rem;
}

.column-list {
  display: flex;
  justify-content: space-between;
}

.column {
  padding: 0 1em;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
}

.table_of_contents-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.125rem;
}

.table_of_contents-indent-1 {
  margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
  margin-left: 3rem;
}

.table_of_contents-indent-3 {
  margin-left: 4.5rem;
}

.table_of_contents-link {
  text-decoration: none;
  opacity: 0.7;
  border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
  border: 1px solid rgba(55, 53, 47, 0.09);
  border-collapse: collapse;
}

table {
  border-left: none;
  border-right: none;
}

th,
td {
  font-weight: normal;
  padding: 0.25em 0.5em;
  line-height: 1.5;
  min-height: 1.5em;
  text-align: left;
}

th {
  color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
  margin: 0;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
  margin-block-start: 0.6em;
}

ul > li {
  list-style: disc;
}

ul.to-do-list {
  text-indent: -1.7em;
}

ul.to-do-list > li {
  list-style: none;
}

.to-do-children-checked {
  text-decoration: line-through;
  opacity: 0.375;
}

ul.toggle > li {
  list-style: none;
}

ul {
  padding-inline-start: 1.7em;
}

ul > li {
  padding-left: 0.1em;
}

ol {
  padding-inline-start: 1.6em;
}

ol > li {
  padding-left: 0.2em;
}

.mono ol {
  padding-inline-start: 2em;
}

.mono ol > li {
  text-indent: -0.4em;
}

.toggle {
  padding-inline-start: 0em;
  list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
  padding-left: 1.7em;
}

.toggle > li > details > summary {
  margin-left: -1.1em;
}

.selected-value {
  display: inline-block;
  padding: 0 0.5em;
  background: rgba(206, 205, 202, 0.5);
  border-radius: 3px;
  margin-right: 0.5em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  white-space: nowrap;
}

.collection-title {
  display: inline-block;
  margin-right: 1em;
}

time {
  opacity: 0.5;
}

.icon {
  display: inline-block;
  max-width: 1.2em;
  max-height: 1.2em;
  text-decoration: none;
  vertical-align: text-bottom;
  margin-right: 0.5em;
}

img.icon {
  border-radius: 3px;
}

.user-icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 0.5rem;
}

.user-icon-inner {
  font-size: 0.8em;
}

.text-icon {
  border: 1px solid #000;
  text-align: center;
}

.page-cover-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 30vh;
}

.page-header-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.page-header-icon-with-cover {
  margin-top: -0.72em;
  margin-left: 0.07em;
}

.page-header-icon img {
  border-radius: 3px;
}

.link-to-page {
  margin: 1em 0;
  padding: 0;
  border: none;
  font-weight: 500;
}

p > .user {
  opacity: 0.5;
}

td > .user,
td > time {
  white-space: nowrap;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin-right: 0.6em;
  vertical-align: middle;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.image {
  border: none;
  margin: 1.5em 0;
  padding: 0;
  border-radius: 0;
  text-align: center;
}

.code,
code {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  tab-size: 2;
}

code {
  color: #eb5757;
}

.code {
  padding: 1.5em 1em;
}

.code-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code > code {
  background: none;
  padding: 0;
  font-size: 100%;
  color: inherit;
}

blockquote {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
  text-decoration: none;
  max-height: 8em;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bookmark-title {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.75em;
  white-space: nowrap;
}

.bookmark-text {
  display: flex;
  flex-direction: column;
}

.bookmark-info {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookmark-image {
  width: 33%;
  flex: 1 1 180px;
  display: block;
  position: relative;
  object-fit: cover;
  border-radius: 1px;
}

.bookmark-description {
  color: rgba(55, 53, 47, 0.6);
  font-size: 0.75em;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}

.bookmark-href {
  font-size: 0.75em;
  margin-top: 0.25em;
}

.sans { font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"; }
.code { font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace; }
.serif { font-family: Lyon-Text, Georgia, ui-serif, serif; }
.mono { font-family: iawriter-mono, Nitti, Menlo, Courier, monospace; }
.pdf .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK JP'; }
.pdf:lang(zh-CN) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC'; }
.pdf:lang(zh-TW) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK TC'; }
.pdf:lang(ko-KR) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK KR'; }
.pdf .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.pdf .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK JP'; }
.pdf:lang(zh-CN) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK SC'; }
.pdf:lang(zh-TW) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK TC'; }
.pdf:lang(ko-KR) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK KR'; }
.pdf .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.highlight-default {
}
.highlight-gray {
  color: rgb(155,154,151);
}
.highlight-brown {
  color: rgb(100,71,58);
}
.highlight-orange {
  color: rgb(217,115,13);
}
.highlight-yellow {
  color: rgb(223,171,1);
}
.highlight-teal {
  color: rgb(15,123,108);
}
.highlight-blue {
  color: rgb(11,110,153);
}
.highlight-purple {
  color: rgb(105,64,165);
}
.highlight-pink {
  color: rgb(173,26,114);
}
.highlight-red {
  color: rgb(224,62,62);
}
.highlight-gray_background {
  background: rgb(235,236,237);
}
.highlight-brown_background {
  background: rgb(233,229,227);
}
.highlight-orange_background {
  background: rgb(250,235,221);
}
.highlight-yellow_background {
  background: rgb(251,243,219);
}
.highlight-teal_background {
  background: rgb(221,237,234);
}
.highlight-blue_background {
  background: rgb(221,235,241);
}
.highlight-purple_background {
  background: rgb(234,228,242);
}
.highlight-pink_background {
  background: rgb(244,223,235);
}
.highlight-red_background {
  background: rgb(251,228,228);
}
.block-color-default {
  color: inherit;
  fill: inherit;
}
.block-color-gray {
  color: rgba(55, 53, 47, 0.6);
  fill: rgba(55, 53, 47, 0.6);
}
.block-color-brown {
  color: rgb(100,71,58);
  fill: rgb(100,71,58);
}
.block-color-orange {
  color: rgb(217,115,13);
  fill: rgb(217,115,13);
}
.block-color-yellow {
  color: rgb(223,171,1);
  fill: rgb(223,171,1);
}
.block-color-teal {
  color: rgb(15,123,108);
  fill: rgb(15,123,108);
}
.block-color-blue {
  color: rgb(11,110,153);
  fill: rgb(11,110,153);
}
.block-color-purple {
  color: rgb(105,64,165);
  fill: rgb(105,64,165);
}
.block-color-pink {
  color: rgb(173,26,114);
  fill: rgb(173,26,114);
}
.block-color-red {
  color: rgb(224,62,62);
  fill: rgb(224,62,62);
}
.block-color-gray_background {
  background: rgb(235,236,237);
}
.block-color-brown_background {
  background: rgb(233,229,227);
}
.block-color-orange_background {
  background: rgb(250,235,221);
}
.block-color-yellow_background {
  background: rgb(251,243,219);
}
.block-color-teal_background {
  background: rgb(221,237,234);
}
.block-color-blue_background {
  background: rgb(221,235,241);
}
.block-color-purple_background {
  background: rgb(234,228,242);
}
.block-color-pink_background {
  background: rgb(244,223,235);
}
.block-color-red_background {
  background: rgb(251,228,228);
}
.select-value-color-default { background-color: rgba(206,205,202,0.5); }
.select-value-color-gray { background-color: rgba(155,154,151, 0.4); }
.select-value-color-brown { background-color: rgba(140,46,0,0.2); }
.select-value-color-orange { background-color: rgba(245,93,0,0.2); }
.select-value-color-yellow { background-color: rgba(233,168,0,0.2); }
.select-value-color-green { background-color: rgba(0,135,107,0.2); }
.select-value-color-blue { background-color: rgba(0,120,223,0.2); }
.select-value-color-purple { background-color: rgba(103,36,222,0.2); }
.select-value-color-pink { background-color: rgba(221,0,129,0.2); }
.select-value-color-red { background-color: rgba(255,0,26,0.2); }

.checkbox {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 16;
  height: 16;
  background-size: 16px;
  margin-left: 2px;
  margin-right: 5px;
}

.checkbox-on {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}


</style>
